<template> 
      <span  class="document-actions">
          <i class="si si-bin" title="Delete" @click="deleteFavouriteById(id, $t('confirm_item_delete'))"></i>
      </span>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {mapActions} = createNamespacedHelpers('quickviews');
export default {
    name: "EditFavorite",
    props: {
      name:{ type:String, default: '' },
      id:{ type: Number, default: null },
    },
    computed: {

    },
    methods: {
        ...mapActions([
                'deleteFavourite',
                'fetchAccordions'
            ]
        ),
        async deleteFavouriteById(id, message) {
            if (confirm(message)) {
                const loader = this.$loading.show();
                const response = await this.deleteFavourite({elementId: id});
                if (response) {
                    loader.hide();
                }
            }
        }
    }
}
</script>

<style scoped>

</style>